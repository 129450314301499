import videojs from 'video.js'
import en from 'video.js/dist/lang/en.json'

videojs.addLanguage('en', en)
videojs.addLanguage('en', {
  'Error': 'Error',
  'Error Code': 'Error Code',

  // add custom english translations here
})
