import videojs from 'video.js'

export type { TPlayer } from 'video.js'
export type { Configuration, Options } from './core'
export type { PlayerRef } from './videojs'

export { mapError, type ExternalError } from './components/error-display'
export { defaultPlayer, miniPlayer } from './components/player'
export { addLanguage, supportedDrmsResolved } from './core'
export { Player, type PlayerProps } from './Player'
export { setPlayerDebugMode } from './shared/constants/debug'
export { Events } from './shared/events'
export { setLogger } from './shared/logger'
export { MediaError } from './shared/media-error'
export * from './shared/types/tracks'

// values for google pal nonce generation
export const name = 'videojs'
export const version = (videojs as any).VERSION
