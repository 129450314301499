import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import {
  BaseMediaLoader,
  BasePlayer,
  BasePosterImage,
  BaseResizeManager,
  BaseTextTrackDisplay,
  BaseTextTrackSettings,
} from '../base'
import { ChildrenSlot } from '../children-slot'
import { DebugOverlay } from '../debug-overlay'
import { ErrorDisplay } from '../error-display'
import { Hotkeys } from '../hotkeys'
import { LiveTracker } from '../live-tracker'
import { TapHandler } from '../tap-handler'

/**
 * Main player component
 */
@register
export class Player extends BasePlayer {
  constructor(tag, options, ready) {
    super(tag, options, ready)

    // manually add classes from options
    if (options.className) {
      options.className.split(' ').forEach((c) => this.addClass(c))
    }
  }

  setNewSource(source) {
    const setSource = () => {
      if (!this.player_ || this.player_?.isDisposed()) return
      this.player_.off('playerreset', setSource)
      this.src(source)
    }

    // we need to wait coz reset is async
    this.player_.on('playerreset', setSource)
    this.resetSource()
  }

  resetSource() {
    this.pause() // pause could make reset operation faster - see doReset_
    this.reset()
  }

  // add "missing" event listener to update media source
  @on(Events.Media.Load)
  updateSource(source: Events.Media.Load) {
    if (source) {
      this.setNewSource(source)
    } else {
      this.resetSource() // in case of empty sources - reset player
    }
  }

  @on(Events.Media.IsRenderedChildren)
  handleChildrenState(isRenderedChildren: boolean) {
    if (isRenderedChildren) this.addClass('vjs-children')
    else this.removeClass('vjs-children')
  }

  @on(Events.Media.ProgressBarChanged)
  handleProgressBarClass(progressBar: Events.Media.ProgressBarChanged) {
    const isInteractive =
      progressBar && (progressBar.length === 4 || progressBar[4])

    if (progressBar === undefined || isInteractive)
      this.addClass('vjs-rewindable-progress-bar')
    else this.removeClass('vjs-rewindable-progress-bar')

    if (progressBar !== undefined) this.addClass('vjs-live-progress-bar')
    else this.removeClass('vjs-live-progress-bar')
  }
}

Player.options = {
  children: [
    // `MediaLoader` decides which playback technology to load when a player is initialized, required to play anything
    BaseMediaLoader.as(),

    // handle keyboard shortcuts for the player
    Hotkeys,

    // debug overlay with stream and player info
    DebugOverlay,

    // clicable component that handles showing the poster image for the player
    BasePosterImage.as(),

    // component for displaying text track cues, required for displaying subtitles
    BaseTextTrackDisplay.as(),

    // checking live current time and determining when the player is at or behind the live edge
    LiveTracker.as('liveTracker'),

    // handle double-tap to seek on touch devices
    TapHandler,

    // display that indicates an error has occurred, this means that the video is unplayable
    ErrorDisplay.as('errorDisplay'),

    // empty slot for children elements rendering
    // add this component as property `.slot` to the player instance
    ChildrenSlot.as('slot'),

    // modal dialog for manipulating text tracks (subtitles) settings
    BaseTextTrackSettings.as(),

    // component to track size of the player, triggers `playerresize` event on player
    BaseResizeManager.as(),

    // disable ad detection for now
    // AdDetection,
  ],
}
