import { useMemo } from 'react'
import type { TSource } from 'video.js'
import { keySystems, type LA } from '|>/core/drm'

/**
 * Hook to convert playback URL and DRM properties to VideoJS source
 * Returns memoized source object, that will not change between renders
 */
export function useSource(url?: string, la?: LA): TSource | undefined {
  return useMemo(() => {
    if (!url) return

    const isDash = url.includes('.mpd')
    const isHls = url.includes('.m3u8')

    // prettier-ignore
    let type = isDash ? 'application/dash+xml'
              : isHls ? 'application/x-mpegURL' // 'application/vnd.apple.mpegurl'
                      : ''

    const source = {
      src: url,
      type,
      keySystems: la ? keySystems(la) : undefined,
    }

    return source
  }, [url /*, JSON.stringify(la)*/])

  // TODO: add la back to deps
  // this is workaround to avoid re-render on la change
  // because due to `usePlaybackUrlAdParams` hook in the app, playback url got updated _later_,
  // than drm properties, causing the player to re-render twice -
  // first time with old playback url and new drm properties,
  // and second time with new playback url and new drm properties.
  // this causes issues with fairplay drm, because it requires to re-init the player.
  // need to update playback url and drm properties in the same render!
}
