import { Events } from '|>/shared/events'
import {
  addClass,
  removeAttribute,
  removeClass,
  setAttribute,
} from '|>/shared/h'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './poster.css'

@register
export class Poster extends BaseComponent {
  declare poster: Events.Controls.SetPoster
  declare posterEl?: HTMLElement

  @on(Events.Controls.SetPoster)
  setPoster(poster: Events.Controls.SetPoster) {
    this.poster = poster

    if (poster) {
      removeClass(this.el_, 'vjs-x-poster-hidden')
      setAttribute(
        this.el_,
        'style',
        // svg default image works only with "background"
        `background: url(${this.poster}) no-repeat center;
      background-size: contain;`
      )
    } else {
      addClass(this.el_, 'vjs-x-poster-hidden')
      removeAttribute(this.el_, 'style')
    }
  }
}

Poster.options = {
  className: 'vjs-x-poster',
}
