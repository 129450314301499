import type { TOptions, TSource } from 'video.js'
import type { ExternalError } from '|>/components/error-display'
import type { Configuration } from '|>/core'
// import type { TAdMatcher } from '|>/components/ad-detection'

/**
 * Custom event names map
 * (maybe should contain native events as well)
 *
 * VideoJS can use any strings for event names, but strings are harder to find and refactor,
 * and also strings can be mistyped, so we use constants here.
 *
 * As a rule of thumb, all custom events names should be wrapped in `[]`
 * to distinguish them from native events and avoid conflicts.
 */
export const Events = {
  Media: {
    Load: '[media-load]',
    TitleChanged: '[media-title-changed]',
    ProgressBarChanged: '[media-progress-bar-changed]',
    IsRewound: '[media-is-rewound]',
    IsRewindPending: '[media-is-rewind-pending]',
    RewoundTargetSec: '[media-rewound-target-sec]',
    RewoundUrlSec: '[media-rewound-url-sec]',
    IsAutoLiveTracker: '[media-is-live-seek-controlled]',
    // AdMatcher: '[media-ad-matcher]',
    // AdStarted: '[media-ad-started]',
    // AdEnded: '[media-ad-ended]',
    IsUrlPending: '[media-is-url-pending]',
    Error: '[media-error]',
    IsRenderedChildren: '[media-is-rendered-children]',
  },

  Controls: {
    SetPoster: '[controls-set-poster]',
    ShowPlayNextControl: '[controls-show-play-next]',
    ShowPlayPreviousControl: '[controls-show-play-previous]',
    RegisterHotkey: '[controls-register-hotkey]',
  },

  Player: {
    UpdateOptions: '[player-update-options]',
    UpdateConfiguration: '[player-update-configuration]',
    Close: '[player-close]',
    LiveRewind: '[player-live-rewind]',
    GoLive: '[player-go-live]',
    PlayNext: '[player-play-next]',
    PlayPrevious: '[player-play-previous]',
    ProgressReachedEnd: '[player-progress-reached-end]',
  },

  // chromecast events, added by `@silvermine/videojs-chromecast` plugin
  // these are not our custom events, so they are not wrapped in brackets
  Chromecast: {
    Connected: 'chromecastConnected', // triggers when Chromecast connected
    Disconnected: 'chromecastDisconnected', // triggers when Chromecast disconnected
    DevicesAvailable: 'chromecastDevicesAvailable', // triggers on state change when Chromecast devices are available
    DevicesUnavailable: 'chromecastDevicesUnavailable', // triggers on state change when Chromecast devices are unavailable
    Requested: 'chromecastRequested', // triggers when the user has requested Chromecast playback using this plugin's Chromecast button
  },
} as const

/**
 * Custom event payloads
 *
 * Defined as nested namespaces to make them look like a tree of events,
 * for example, payload for event `Events.Media.Load` has type `Events.Media.Load`
 */
export namespace Events {
  export namespace Media {
    export type Load = TSource | undefined

    export type TitleChanged = {
      title?: string
      subtitle?: string
      logo?: string
    }
    export type ProgressBarChanged =
      | [number, number, number, number] // [start, seekStart, seekEnd, end]
      | [number, number, number, number, boolean?] // [start, seekStart, seekEnd, end, isInteractive = true]
      | null // disabled and non-interactive progress bar (but visible)
      | false // disabled and hidden progress bar
      | undefined // no data for live progressBar - vod progressBar showed
    export type IsRewound = boolean
    export type IsRewindPending = boolean
    export type RewoundTargetSec = number
    export type RewoundUrlSec = number
    export type IsAutoLiveTracker = boolean

    // numbers (code) defined here https://github.com/videojs/video.js/blob/d2b9d5c974036e637df133f38a95649ab2230490/src/js/media-error.js#L136
    // export type AdMatcher = TAdMatcher | undefined
    // export type AdStarted = undefined
    // export type AdEnded = undefined
    export type IsUrlPending = boolean | undefined
    export type Error = ExternalError | null | undefined
    export type IsRenderedChildren = boolean
  }
  export namespace Controls {
    export type SetPoster = string | undefined
    export type ShowPlayNextControl = boolean
    export type ShowPlayPreviousControl = boolean

    export type ArrayHotkey = [string | string[], () => void]
    export type ObjectHotkey = { key: string | string[]; handler: () => void }
    export type Hotkey = ArrayHotkey | ObjectHotkey
    export type RegisterHotkey = Hotkey | Hotkey[]
  }
  export namespace Player {
    export type UpdateOptions = TOptions
    export type UpdateConfiguration = Configuration
    export type Close = undefined
    export type LiveRewind = number | undefined
    export type GoLive = undefined
    export type PlayNext = undefined
    export type PlayPrevious = undefined
    export type ProgressReachedEnd = undefined
  }
}

//
// Following types are utility types and should not be used in the code,
// aside from special cases for type inference, like in `useEventTrigger` and `useEventListener`
//

/**
 * Utility type to get the event payload type by event string name,
 * for example, `EventTypeMap[Events.Media.Load]` is `Events.Media.Load`
 * It is impossible to get the type by string directly in TypeScript, so we use this for mapping.
 */
// prettier-ignore
export type EventTypeMap = {
  [Events.Media.Load]: Events.Media.Load
  [Events.Media.TitleChanged]: Events.Media.TitleChanged
  [Events.Media.ProgressBarChanged]: Events.Media.ProgressBarChanged
  [Events.Media.IsRewound]: Events.Media.IsRewound
  [Events.Media.IsRewindPending]: Events.Media.IsRewindPending
  [Events.Media.RewoundTargetSec]: Events.Media.RewoundTargetSec
  [Events.Media.RewoundUrlSec]: Events.Media.RewoundUrlSec
  [Events.Media.IsRenderedChildren]: Events.Media.IsRenderedChildren
  [Events.Media.IsAutoLiveTracker]: Events.Media.IsAutoLiveTracker
  // [Events.Media.AdMatcher]: Events.Media.AdMatcher
  // [Events.Media.AdStarted]: Events.Media.AdStarted
  // [Events.Media.AdEnded]: Events.Media.AdEnded
  [Events.Media.IsUrlPending]: Events.Media.IsUrlPending
  [Events.Media.Error]: Events.Media.Error
  [Events.Controls.SetPoster]: Events.Controls.SetPoster
  [Events.Controls.ShowPlayNextControl]: Events.Controls.ShowPlayNextControl
  [Events.Controls.ShowPlayPreviousControl]: Events.Controls.ShowPlayPreviousControl
  [Events.Controls.RegisterHotkey]: Events.Controls.RegisterHotkey
  [Events.Player.UpdateOptions]: Events.Player.UpdateOptions
  [Events.Player.UpdateConfiguration]: Events.Player.UpdateConfiguration
  [Events.Player.Close]: Events.Player.Close
  [Events.Player.LiveRewind]: Events.Player.LiveRewind
  [Events.Player.GoLive]: Events.Player.GoLive
  [Events.Player.PlayNext]: Events.Player.PlayNext
  [Events.Player.PlayPrevious]: Events.Player.PlayPrevious
  [Events.Player.ProgressReachedEnd]: Events.Player.ProgressReachedEnd
}

/**
 * Utility type to get the event payload type by event string name,
 * for example, `EventType<Events.Media.Load>` is `Events.Media.Load`
 */
export type EventType<T extends keyof EventTypeMap> = EventTypeMap[T]
