import { register } from '|>/shared/vjs'
import { BaseButton } from '../base'

import { REWIND_SEC } from '|>/shared/constants/rewind'
import './rewind-button.css'

/**
 * Play next button component
 */
@register
export class RewindButton extends BaseButton {
  override handleClick() {
    const direction = this.options_.className.includes('left') ? -1 : 1
    const curTime = this.player_.currentTime()
    const deltaTime = direction * REWIND_SEC
    const newTime = curTime + deltaTime
    this.player_.currentTime(newTime)
  }
}
